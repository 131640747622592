<div>
    <div class="row">
        <div class="col-md-4 img_div">
            <div class="card">
                <div class="card-header">
                    <span>Profile Picture</span>
                    <input type="file" (change)="changeFile($event)" class="ml-1">
                </div>
                <div class="card-body">
                    <img class="card-img-bottom" [src]="getImage()" alt="No image" style="width:100%">
                </div>
                <div class="card-footer">
                    <button class="btn btn-success"(click)='upload()'>Upload</button>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    <span>About Me</span>
                </div>
                <div class="card-body">
                    <textarea [(ngModel)]='personalData.PROFILESUMMARY' placeholder="About Me" rows="4"></textarea>
                </div>

            </div>
        </div>
    </div>
</div>