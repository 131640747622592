import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-cource-category',
  templateUrl: './cource-category.component.html',
  styleUrls: ['./cource-category.component.css']
})
export class CourceCategoryComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  constructor(private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService) {
    super(CommonService,toastr);
    this.loadCourseCategory()
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      MNAME: ['', Validators.required,],
      MDESCRIPTION: ['',],
      MSTATUS: [true]
    })
  }

  setDefault() {
    let ctrl = this.myForm.controls;
    ctrl['MSTATUS'].setValue(true)
  }

  loadCourseCategory() {
    this.activeSpinner()
    let payLoad = {
      TENANT_CODE: this.tId||this.TenantCode
    }
    this.CommonService.postCall("LoadCourseCategory", payLoad).subscribe((res: any) => {
     this.table=[];
   
      this.table = res;
      this.renderDataTable() 
     

      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }


  close() {
    this.isEdit = false;
    this.myForm.reset();
    this.setDefault();
  }
  add() { }
  edit(data) {
    let payLoad = this.editData = {
      MID: data.COURSE_CATEGORY_ID,
      TENANT_CODE:localStorage.getItem('TenantCode')
    }
    this.isEdit = true;
    this.CommonService.postCall('EditCourseCategory', payLoad).subscribe((res: any) => {
      if(res.length){
        this.editData = res[0];
       }else{
        this.editData = res;
       }
       this.editData['COURSE_CATEGORY_ID']=data.COURSE_CATEGORY_ID
      this.setData();
    }, err => { })
  }
  setData() {
    let ctrls: any = this.myForm.controls;
    // Object.keys(ctrls).map((key: string) => {
    //   let control: FormControl = ctrls[key];
    //   control.setValue(this.editData[key])
    // })
    ctrls['MSTATUS'].setValue(this.editData['COURSE_CATEGORY_STATUS']?true:false)
    ctrls['MNAME'].setValue(this.editData['COURSE_CATEGORY_NAME'])
    ctrls['MDESCRIPTION'].setValue(this.editData['COURSE_CATEGORY_DESCRIPTION']);
  }
  onSubmit(form: FormGroup) {
    let payLoad = form.value;
    if(form.invalid) return
    payLoad["CREATEDBY"] = this.editData.CREATEDBY || localStorage.getItem('UserId');
    payLoad.TENANT_CODE = localStorage.getItem('TenantCode');
    if (this.isEdit) {
      payLoad.LASTMDFDATE = moment(new Date())
      payLoad.MID = this.editData.MID||this.editData.COURSE_CATEGORY_ID;
      this.CommonService.postCall('UpdateCourseCategory', payLoad).subscribe((res: any) => {
        this.loadCourseCategory();
        this.toastr.success('Course Category Updated Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error?err.error:'Course Category  not Updated')
      })
    } else {
      payLoad.CREATEDDATE = moment(new Date())
      this.CommonService.postCall('CreateCourseCategory', payLoad).subscribe((res: any) => {
        this.loadCourseCategory();
        this.toastr.success('Course Category created Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error?err.error:'Course Category not created');
      })
    }
  }
  changeTname() {
    this.loadCourseCategory()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
  
}