import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  addrTypes: Array<any> = [];
  country: Array<any> = [];
  states: Array<any> = [];
  city: Array<any> = [];
  Address: Array<any> = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      AddressType: ['', Validators.required],
      Country: ['', Validators.required],
      State: ['', Validators.required],
      City: ['', Validators.required],
      Pincode: ['', Validators.required],
      AddressDetails: ['', Validators.required]

    });
    this.getAll();
    this.parent.childs['AddressList'] = this
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      // this.Address = this.editData['Address'].slice(0);
      this.dataTransform(this.editData['Address'].slice(0));
    }
  }
  dataTransform(data: Array<any>) {
    // this.table = data
    data.map(item => {
      let obj = {
        type: item['TYPE'],
        AddressId: item['ADDRESSID'],
        AddressTypeName: item['ADDRESSTYPE'],
        AddressType: '',
        Country: '',
        CountryName: item['COUNTRY'],
        State: '',
        StateName: item['STATE'],
        City: '',
        CityName: item['CITY'],
        Pincode: item['PINCODE'],
        AddressDetails: item['ADDRESSDETAILS']
      }
      this.table.push(obj)
    });
    this.Address = this.table.slice(0);
    this.setIds()
  }
  getAll() {
    let types = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.AddressType});//GetAddressType
    let country = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.Country});
    forkJoin([types, country,]).subscribe((res) => {
      [this.addrTypes, this.country,] = [...res]
    })

  }
  changeCountry(value) {
    this.parent.activeSpinner();//state
    this.parent.CommonService.postCall('GetChildDictionary', { DictionaryID: value }).subscribe(
      (res: any) => {
        this.parent.deactivateSpinner();
        this.states = res;
      },
      err => {
        this.parent.deactivateSpinner();
      }
    )
  }
  changeState(value) {
    this.parent.activeSpinner();//GetCityByStateId
    this.parent.CommonService.postCall('GetChildDictionary', { DictionaryID: value }).subscribe(
      (res: any) => {
        this.parent.deactivateSpinner();
        this.city = res;
      },
      err => {
        this.parent.deactivateSpinner();
      }
    )
  }
  add() {
    this.isEdit = false;
    this.setDefaults()
  }
  setDefaults() {
    let keys = ['AddressType', 'Country', 'State', 'City'];
    let ctrls = this.myForm.controls;
    keys.map((key) => {
      let ctrl = ctrls[key];
      ctrl.setValue('')
    })
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData=data;
    this.assignDataForm(i);
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      let data = Object.assign({}, value);
      this.table[this.index] = data;
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.table.push(data);
    }
    document.getElementById('md_close_lan').click();
  }
  setIds(){

  }
  assignDataForm(i){
    let data=this.Address[i];
    let ctrls=this.myForm.controls;
    Object.keys(ctrls).map((formControlName:string)=>{
      let control:AbstractControl=ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
}
