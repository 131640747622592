<div class="default_class">
  <div class="content-wrapper p-0" id="ui">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="card rounded shadow-lg">
            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
              <h5 class="mb-0 text-center">Data Dictionary</h5>
            </div>

            <div class="card-body">
              <div class="text-right">
                <button type="button" class="btn btn-primary mb-1" data-toggle="modal" data-target="#myModal"
                  (click)="add()">Add</button>
              </div>

              <div *ngIf="table.length">
                <table id="patreggraph" class=" table-striped table-bordered mt-2 pt-2  center "
                  style="width:100%; text-align: center;" datatable [dtOptions]="dtOptions">
                  <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                    <tr>
                      <th> Name</th>
                      <th>Belongs TO</th>
                      <th>Status </th>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of table ">
                      <td>{{item.DICTIONARYNAME}}</td>
                      <td>{{item.PARENT}}</td>
                      <td>{{item.STATUS}}</td>
                      <td><img src="./../../../assets/images/sprites/edit.svg" class="edit_icon" data-toggle="modal"
                          (click)="edit(item)" data-target="#myModal"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center" *ngIf="false">
                No Records to display
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">

    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title text-center">Data Dictionary </h2>
      </div>

      <div class="modal-body">
        <form class="well form-horizontal" [formGroup]="myForm" novalidate>
          <div class="col-sm-12">
            <div class="form-group clearfix">
              <label>Dictionary Name</label>
              <input type="text" class="form-control" name="name" formControlName="DictionaryName" required>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group clearfix">
              <label>Description</label>
              <textarea rows="4" cols="50" name="description" formControlName="Description">
                              Enter text here... </textarea>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group clearfix">
              <label>Belongs To </label>
              <select class="custom-select" class="form-control" formControlName="ParentDicId">
                <option *ngFor="let c of parents" [value]="c.DICTIONARYID">
                  {{ c.DICTIONARYNAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group clearfix">
              <label> Group</label>
              <select class="custom-select" formControlName="GroupId">
                <option *ngFor="let c of group" [value]="c.DICTIONARYID">
                  {{ c.DICTIONARYNAME }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group clearfix">
              <label> Status</label>
              <select class="form-control" name="status" formControlName="Status" required>
                <option [ngValue]=true>Active</option>
                <option [ngValue]=false>InActive </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
          [disabled]="myForm.invalid">Add</button>
        <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
          [disabled]="myForm.invalid">Update</button>
        <button type="button" class="btn btn-danger" id='md_close' (click)="close()" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
