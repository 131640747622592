
<div class="contact_details">
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" 
           >Add</button>

    </div>
    <table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>Is Primary</th>
                <th>Email Id</th>
                <th>Mobile No</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td><input type="checkbox"   [(ngModel)]='item.ISPRIMARY' [disabled]='!item.isedit'></td>
                <td  *ngIf='item.isedit'><input type="email"  class="form-control form-control-sm shadow-sm rounded-lg"  [(ngModel)]='item.EMAILID' ></td>
                <td *ngIf='!item.isedit' >{{item.EMAILID}} <span>{{item.IsConfirmed}}</span></td>
                <td  *ngIf='item.isedit'><input type="text"  class="form-control form-control-sm shadow-sm rounded-lg"  [(ngModel)]='item.MOBILENO' onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' *ngIf='item.isedit' ></td>
                <td *ngIf='!item.isedit' >{{item.MOBILENO}} </td>
                <td *ngIf='item.isedit' (click)='save(item,i)'><button class="btn btn-success">	&#8594;</button></td>
                <td *ngIf='!item.isedit' class=" text-center"><input type="image"      src="./../../../assets/images/sprites/edit.svg" class="edit_icon text-center" (click)="edit(item)"> </td>
            </tr>
        </tbody>
    </table>
</div>
