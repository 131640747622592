import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare var $: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
   data:any={}
  constructor(private userService: LoginService, private route: Router, private toastr: ToastrService, private CommonService: CommonService) { }

  ngOnInit(): void {
    this.init()
  }
  registerUser() {
    let user = this.data;
    this.userService.login(user).subscribe((succ) => {
      let userData:any = succ??{};
      this.CommonService.userId = userData.USERID
      localStorage.setItem('UserId', userData.USERID);
      localStorage.setItem('Username', userData.FIRSTNAME);
      localStorage.setItem('TenantCode', userData.TENANTCODE)
      localStorage.setItem('RoleId', userData.ROLEID)
      localStorage.setItem('DICTIONARYCODE',userData.DICTIONARYCODE)
      localStorage.setItem('SU',userData.samvaad_user);
      localStorage.setItem('SP',userData.samvaad_password);
      // this.toastr.success('successfully loggedIn');
      this.route.navigate(['/home'])

    }, (err) => {
     console.log(err)
      let e = err.error;
      
      this.toastr.error(e?.text||'Please try again later')
    })
  }

  init() {
    let self=this;
    $('.input100').each(function () {
      $(this).on('blur', function () {
        if ($(this).val().trim() != "") {
          $(this).addClass('has-val');
        }
        else {
          $(this).removeClass('has-val');
        }
      })
    })

    var input = $('.validate-input .input100');

    $('.validate-form').on('submit',function(){
        var check = true;

        for(var i=0; i<input.length; i++) {
            if(validate(input[i]) == false){
                showValidate(input[i]);
                check=false;
            }
        }
        if(check){
           self.registerUser()
        }
        return check;
    });


    $('.validate-form .input100').each(function(){
        $(this).focus(function(){
           hideValidate(this);
        });
    });

    function validate (input) {
      // return true
        if($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
          return true
            if($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
                return false;
            }
        }
        else {
            if($(input).val().trim() == ''){
                return false;
            }
        }
    }

    function showValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).addClass('alert-validate');
    }

    function hideValidate(input) {
        var thisAlert = $(input).parent();

        $(thisAlert).removeClass('alert-validate');
    }

    var showPass = 0;
    $('.btn-show-pass').on('click', function(){
        if(showPass == 0) {
            $(this).next('input').attr('type','text');
            $(this).find('i').removeClass('zmdi-eye');
            $(this).find('i').addClass('zmdi-eye-off');
            showPass = 1;
        }
        else {
            $(this).next('input').attr('type','password');
            $(this).find('i').addClass('zmdi-eye');
            $(this).find('i').removeClass('zmdi-eye-off');
            showPass = 0;
        }
        
    });
    
  }
  
  

}
