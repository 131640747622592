<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Training Calendar</h5>
                        </div>
                        <div class="card-body">
                            <div class="row text-center">
                                <div class="col-md-4">
                                  <div class="btn-group">
                                    <div
                                      class="btn btn-primary"
                                      mwlCalendarPreviousView
                                      [view]="view"
                                      [(viewDate)]="viewDate"
                                      (viewDateChange)="closeOpenMonthViewDay()"
                                    >
                                      Previous
                                    </div>
                                    <div
                                      class="btn btn-outline-secondary"
                                      mwlCalendarToday
                                      [(viewDate)]="viewDate"
                                    >
                                      Today
                                    </div>
                                    <div
                                      class="btn btn-primary"
                                      mwlCalendarNextView
                                      [view]="view"
                                      [(viewDate)]="viewDate"
                                      (viewDateChange)="closeOpenMonthViewDay()"
                                    >
                                      Next
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                </div>
                                <div class="col-md-4">
                                  <div class="btn-group">
                                    <div
                                      class="btn btn-primary"
                                      (click)="setView(CalendarView.Month)"
                                      [class.active]="view === CalendarView.Month"
                                    >
                                      Month
                                    </div>
                                    <div
                                      class="btn btn-primary"
                                      (click)="setView(CalendarView.Week)"
                                      [class.active]="view === CalendarView.Week"
                                    >
                                      Week
                                    </div>
                                    <div
                                      class="btn btn-primary"
                                      (click)="setView(CalendarView.Day)"
                                      [class.active]="view === CalendarView.Day"
                                    >
                                      Day
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div [ngSwitch]="view">
                                <mwl-calendar-month-view
                                  *ngSwitchCase="CalendarView.Month"
                                  [viewDate]="viewDate"
                                  [events]="events"
                                  [refresh]="refresh"
                                  [activeDayIsOpen]="activeDayIsOpen"
                                  (dayClicked)="dayClicked($event.day)"
                                  (eventClicked)="handleEvent('Clicked', $event.event)"
                                  (eventTimesChanged)="eventTimesChanged($event)"
                                >
                                </mwl-calendar-month-view>
                                <mwl-calendar-week-view
                                  *ngSwitchCase="CalendarView.Week"
                                  [viewDate]="viewDate"
                                  [events]="events"
                                  [refresh]="refresh"
                                  (eventClicked)="handleEvent('Clicked', $event.event)"
                                  (eventTimesChanged)="eventTimesChanged($event)"
                                >
                                </mwl-calendar-week-view>
                                <mwl-calendar-day-view
                                  *ngSwitchCase="CalendarView.Day"
                                  [viewDate]="viewDate"
                                  [events]="events"
                                  [refresh]="refresh"
                                  (eventClicked)="handleEvent('Clicked', $event.event)"
                                  (eventTimesChanged)="eventTimesChanged($event)"
                                >
                                </mwl-calendar-day-view>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>