<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">



                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Subscription  Tasks</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group " >
                                <div class="row">
                                   
                                    <div class="col-md-3 ">
                                        <label for="sel1">	Subscription Type <span class="text-danger">*</span>: </label>
                                        <select [(ngModel)]='SubscriptionId'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="subscriptionChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of types" [value]='item.SUBSCRIPTIONID'>{{item.SUBSCRIPTIONTYPENAME}}</option>

                                        </select>
                                    </div>
                                   
                                   
                                    
                                </div>


                            </div>

                            <div>
                                <div class="row p-2" style="align-items: center">
                                    <div class="col-md-5">
                                        <div class="box_cnt">
                                           <div class="pointer" (click)="checkRight($event,item,table)"  *ngFor="let item of table" [ngClass]='item.class'>
                                             {{item.TASKNAME}}
                                           </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1">

                                      <div>
                                       <div>  <button class="btn btn-outline-dark" (click)="shiftLeft()" [disabled]='enableLeft'><i class="mdi mdi-arrow-left"></i></button></div> 
                                       <div> <button class="btn btn-outline-dark" (click)="shiftRight()"  [disabled]='enableRight'> <i class="mdi mdi-arrow-right"></i></button></div> 
                                      <div>   <button class="btn btn-outline-dark" (click)="shiftAllLeft()" [disabled]='!table1.length'><i class="mdi mdi-skip-previous"></i></button></div> 
                                         <div> <button class="btn btn-outline-dark" (click)="shiftAllRight()" [disabled]='!table.length'> <i class="mdi mdi-skip-next"></i></button></div> 
                                      </div>

                                    </div>
                                    <div class="col-md-5">
                                        <div class="box_cnt">
                                            <div class="pointer" (click)="checkRight($event,item,table1)" *ngFor="let item of table1" [ngClass]='item.class'>
                                                {{item.TASKNAME}}
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           
                           
                            <div class="text-center mt-2 ">
                                <button type="button"  (click)="submit()" class="btn btn-primary">Assign</button> &nbsp;
                                <button type="button" (click)="clearAll()" class="btn btn-secondary mr-4" >Reset</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>