<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"
            data-target="#myModal_work">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>
                    Employer  Name</th>
                <th>Designation</th>
                <th>	Employement Status</th>
                <th>	Notice Period</th>
                <th>	Duration From</th>
                <th>	Duration To</th>
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.EmployerName}}</td>
                <td>{{item.Designation}}</td>
                <td>{{item.EmployementStatusName}}</td>
                <td>{{item.NoticePeriod}}</td>
                <td>{{item.DurationFrom}}</td>
                <td>{{item.DurationTo}}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" data-toggle="modal" data-target="#myModal_work" (click)="edit(item,i)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_work" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Work Experience </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Work Experience</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Employer Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Employer Name " formControlName='EmployerName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('EmployerName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EmployerName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Employement Status <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='EmployementStatus'
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Employement Status</option>
                                    <option *ngFor='let item of empStatus' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('EmployementStatus').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EmployementStatus').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Duration <span class="text-danger">*</span></label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationFrom'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationFrom').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationFrom').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <label class="col-md-1 control-label">To</label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationTo'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationTo').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationTo').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>       
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Designation  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Designation" class="form-control" formControlName="Designation">
                                   
                                </div>
                                <div *ngIf="myForm.get('Designation').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Designation').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Job Profile <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="Enter Job Profile" class="form-control" formControlName="JobProfile">
                                    </textarea>
                                </div>
                                <div *ngIf="myForm.get('JobProfile').touched">
                                    <span class="text-danger" *ngIf="myForm.get('JobProfile').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Notice Period <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Notice Period" type="number" min="1" class="form-control" formControlName="NoticePeriod">
                                   
                                </div>
                                <div *ngIf="myForm.get('NoticePeriod').touched">
                                    <span class="text-danger" *ngIf="myForm.get('NoticePeriod').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_work" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>