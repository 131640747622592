<nav class="sidebar slide" id="sidebar">
  <ul class="nav" style="margin-top: 5rem;">

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['dashboard']">
        <span class="menu-title">Dashboard</span>
        <i class="mdi mdi-view-dashboard menu-icon"></i>
      </a>
    </li>

   

    <li class="nav-item" *ngFor=' let item of menus'>
      <a *ngIf="item.childs.length" class="nav-link" [attr.href]="'#'+getId(item.Name)" data-toggle="collapse"
        aria-expanded="false" aria-controls="ui-basic">
        <span class="menu-title">{{item.Name}}</span>
        <i class="menu-arrow"></i>
        <i [className]='item.CssClass' class="mdi mdi-clipboard-check menu-icon"></i>

      </a>
      <div *ngIf="item.childs.length" class="collapse" [attr.id]="getId(item.Name)">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item dropdown" *ngFor="let subMenu of item.childs"> <a class="nav-link"
              (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}} </a>
              <ul *ngIf='subMenu.childs.length' class="dropdown-menu p-3 bg-transparent border-0" style="top:0px;">
                <li class="nav-item"  (click)="navigate(menu.path,menu)"  *ngFor="let menu of subMenu.childs"> <a class="nav-link" >{{menu.Name}} </a></li>
              </ul>
          </li>
        </ul>
      </div>

    </li>


    
    <li class="nav-item" *ngIf='false'>
      <a class="nav-link" data-toggle="collapse" href="#ui-basic6" aria-expanded="false" aria-controls="ui-basic">
        <span class="menu-title">Assessments</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-clipboard-check menu-icon"></i>
      </a>
      <div class="collapse">
        <ul class="nav flex-column sub-menu">
          <li class="dropdown nav-item">
            <div class="">
              <a href="#" class="dropdown-toggle nav-link" aria-haspopup="true" aria-expanded="false">Dropdown <span
                  class="caret"></span></a>

              <ul class="dropdown-menu p-3 bg-transparent border-0" style="top:0px;">
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createFAQs']">Create FAQs</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['news']">Create News</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createPolls']">Create Poll</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['createSurvey']">Create Survey</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackQuestionnaire']">Feedback
                    Questionnaire</a>
                </li>
              </ul>

            </div>
          </li>
        </ul>
      </div>
    </li>

    <div *ngIf='false'>
      <li *ngIf="roleId==2" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic3" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Resources
          </span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-library-books menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic3">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['timeTracker']">Time Tracker </a></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic2" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Assignments</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-clipboard-check menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic2">
          <ul class="nav flex-column sub-menu">
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['assignments']">Assignments </a>
            </li>
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['evaluateAssignments']">Evaluate
                Assignments </a></li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['submitassignment']">Submit
                Assignments </a></li>
          </ul>
        </div>
      </li>
      <li *ngIf="roleId==1" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">MasterData</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-book-multiple menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic1">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['attainmentlevel']">Attainment level</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createFAQs']">Create FAQs</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['news']">Create News</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createPolls']">Create Poll</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['createSurvey']">Create Survey</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackQuestionnaire']">Feedback
                Questionnaire</a>
            </li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['feedbackResults']">Feedback Results</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['smtp']">SMTP Configuration</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['taskmanager']">Task Manager</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['role']">Role</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['taskInRole']">Task In Roles</a></li>

          </ul>
        </div>
      </li>

      <li *ngIf="roleId==3" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Materials</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-book-multiple menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic1">
          <ul class="nav flex-column sub-menu">

            <li class="nav-item"> <a class="nav-link" [routerLink]="['learningmaterial']">Learning Materials </a></li>

          </ul>
        </div>
      </li>


      <li *ngIf="roleId==2" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic3" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Learning Resources
          </span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-library-books menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic3">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['timeTracker']">Time Tracker </a></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic6" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Assessments</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-clipboard-check menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic6">
          <ul class="nav flex-column sub-menu">
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['evaluateAssessment']">Evaluate
                Assessments </a></li>
            <li *ngIf="roleId!=3" class="nav-item"> <a class="nav-link" [routerLink]="['examResult']">Exam Result </a>
            </li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['postassessment']">Post
                Assessments
              </a></li>
            <li *ngIf="roleId==3" class="nav-item"> <a class="nav-link" [routerLink]="['myresults']">My Results </a>
            </li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link"
                [routerLink]="['assessmentQuestionnaire']">Assessment Questionnaire </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['scheduleAssessment']">Schedule
                Assessment </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['masterAssessment']">Master
                Assessment </a></li>
            <li *ngIf="roleId==1" class="nav-item"> <a class="nav-link" [routerLink]="['attendance']">Attendance </a>
            </li>



          </ul>
        </div>
      </li>
      <li class="nav-item" *ngIf="roleId!=2" style="position: relative;">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
          <span class="menu-title">Communication</span>
          <i class="menu-arrow"></i>
          <i class="mdi mdi-account-switch menu-icon"></i>
        </a>
        <div class="collapse" id="ui-basic">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"> <a class="nav-link" [routerLink]="['surveys']">Take Surveys</a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['poll']">Take Poll </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['joinconference']">Join Conference </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['mailBox']">Mail Box </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['forums']">Forums </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['forumTopics']">Forums Topics </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['myPosts']">My posts </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['allBlogs']">All Blogs </a></li>
            <li class="nav-item"> <a class="nav-link" [routerLink]="['blogs']">Blogs </a></li>




          </ul>
        </div>
      </li>



      <li *ngIf="roleId ==1" class="nav-item" style="position: relative;">

        <div>
          <a class="nav-link" data-toggle="collapse" href="#ui-basic12" aria-expanded="false" aria-controls="ui-basic">

            <span class="menu-title">Admin</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-clipboard-check menu-icon"></i>

          </a>
          <div style="position: relative;">
            <div class="collapse" id="ui-basic12">
              <ul class="nav flex-column sub-menu">

                <!-- <li class="nav-item"> <a class="nav-link" [routerLink]="['courceSessions']">Course Sessions </a></li> -->
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courseCategory']">Course Category</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courseType']">Course Type</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['cources']">Courses</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['courceSchedule']">Course Schedule</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['chapters']">Chapters</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['enroll']">Enroll Students</a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['regulations']">Regulations </a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['rooms']">Rooms </a></li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['configurematerials']">Configure Materials </a>
                </li>
                <li class="nav-item"> <a class="nav-link" [routerLink]="['contentmanager']">Content Manager </a></li>



              </ul>
            </div>
          </div>
        </div>
      </li>

    </div>


  </ul>
</nav>