<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">All Blogs</h5>
                        </div>
                        <div class="card-body">
                            <app-published-posts [type]='"allBlogs"'></app-published-posts>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
