<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">



                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0">Feedback Results</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group ">
                                <div class="row">
                                    <div *ngIf='isSuperAdmin' class="col-md-3">
                                        <label for="sel1">Tenant Name : </label>
                                        <select [(ngModel)]='tId ' (change)="changeTname()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                {{item.TNT_NAME}}</option>
                                        </select>

                                    </div>
                                    <div class="col-md-3 ">
                                        <label for="sel1">Users: </label>
                                        <select [(ngModel)]='userId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="userChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let user of users" [value]='user.USERID'>
                                                {{user.USERNAME}}
                                            </option>

                                        </select>
                                    </div>

                                </div>


                            </div>


                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>

                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">No Records to Display</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>