<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">User Registration</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group " >
                                <div class="row">
                                    <div class="col-md-3" *ngIf='roleId=="4"'>
                                        <label for="sel1">Tenant Name  : </label>
                                        <select [(ngModel)]='tId ' (change)="changeTname()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option  value="" [selected]="true">select</option>
                                            <option  *ngFor="let item of tenanates" [value]='item.TNT_CODE'>{{item.TNT_NAME}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">User Role : </label>
                                        <select [(ngModel)]='rId ' (change)="change()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option  value="" [selected]="true">select</option>
                                            <option  *ngFor="let item of roles" [value]='item.ROLE_ID'>{{item.ROLE_NAME}}</option>
                                        </select>
                                    </div>
                                    
                                </div>


                            </div>
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"   [disabled]='!rId' (click)="add()" >Add</button>      
                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th> User Name </th>
                                            <th>
                                                First Name
                                            </th>
                                            <th>	Role  </th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                               <td>{{item.USERNAME}}</td>
                                               <td>{{item.FIRSTNAME}}</td>
                                               <td>{{item.ROLE_NAME}}</td>
                                               <td>{{item.STATUS}}</td>
                                              <td class=" text-center"><input type="image"      src="./../../../assets/images/sprites/edit.svg" class="edit_icon text-center" (click)="edit(item)"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

