<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"
            data-target="#myModal_education">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>Education Type</th>
                <th>Education Mode</th>
                <th>Education Name</th>
                <th>Specialization</th>
                <th>University Institute</th>
                <th>Year Of Passing</th>
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.EducationType}}</td>
                <td>{{item.EducationMode}}</td>
                <td>{{item.EducationName}}</td>
                <td>{{item.Specialization}}</td>
                <td>{{item.UniversityInstitute}}</td>
                <td>{{item.YearOfPassing}}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" (click)="edit(item)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_education" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Education</h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Education</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Education Type <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EducationType'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationTypes' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('EducationType').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EducationType').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Education Mode <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EducationMode' (change)='modeChange($event.target.value)'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationModes' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('EducationMode').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EducationMode').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Education Name <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EducationName'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationNames' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>

                                    </select>
                                </div>
                                <div *ngIf="myForm.get('EducationName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EducationName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Specialization <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='Specialization'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of Specialization' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('Specialization').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Specialization').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> University Institute <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='UniversityInstitute'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of Institutes' [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('UniversityInstitute').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('UniversityInstitute').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Year Of Passing <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min='1' placeholder="" class="form-control"
                                        formControlName="YearOfPassing">
                                </div>
                                <div *ngIf="myForm.get('YearOfPassing').touched">
                                    <span class="text-danger" *ngIf="myForm.get('YearOfPassing').hasError('required')">
                                        This field is required.
                                    </span>
    
                                </div>
                            </div>
                          
                        </div>



                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_education" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>