<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0">Task Manager</h5>
                        </div>

                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div>
                               <div *ngIf="table.length">
                                <table id="patreggraph" datatable class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th>Task Name</th>
                                            <th>Belongs To</th>
                                            <th>Task Type</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                            
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                              <td>{{item.TASKNAME}}</td>
                                              <td>{{item.PARENT}}</td>
                                              <td>{{item.TASKTYPE}}</td>
                                              <td>{{item.STATUS}}</td>
                                              <td class=" text-center"><input type="image" data-toggle="modal"  data-target="#myModal"   src="./../../../assets/images/sprites/edit.svg" class="edit_icon text-center" (click)="edit(item)"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">No Records to Display</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content ">

            <!-- Modal Header -->
            <div class="modal-header  text-center">
                <h4 *ngIf="!isEdit" class="modal-title text-center"> Add Task </h4>
                <h4 *ngIf="isEdit" class="modal-title text-center"> Edit Task</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>   
                        <div class="form-group row">
                            <label class="col-md-4 control-label">	Task Type	 <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"  formControlName="TaskType" >
                                        <option value="">select</option>
                                        <option  value='51' >Module</option>
                                        <option value='52'>Work Item </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                Task Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="TaskName"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                Task Url <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="TaskUrl"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                Description </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <textarea placeholder="" class="form-control"
                                        formControlName="Description"></textarea></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">	Belongs To </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"  formControlName="ParentTaskId" >
                                        <option value=""> select</option>
                                        <option *ngFor="let item of parents" [value]="item.TASKID">{{item.TASKNAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                Show in Grid</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="checkbox" placeholder="" 
                                        formControlName="ShowInGrid"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                Show in Dashboard </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="checkbox" placeholder="" 
                                        formControlName="ShowInDashboard"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 ">
                                CSS Class </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="text" placeholder="" class="form-control"
                                        formControlName="CSSClass"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Status  <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"  formControlName="Status" >
                                        <option  value=true >Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        
                    </fieldset>
                </form>
                
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Save</button> 
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Update</button> 
                <button
                    type="button" class="btn btn-danger" id="md_close"  (click)="close()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>