<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Course Schedule</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  
                                    (click)="add()" >Add</button>      
                            </div>
                            <div class="form-group ">
                                <div class="row">
                                    <div *ngIf='isSuperAdmin' class="col-md-3">
                                        <label for="sel1">Tenant Name : </label>
                                        <select [(ngModel)]='tId ' (change)="changeTname()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                {{item.TNT_NAME}}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div >
                                <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th>Course Name </th>
                                            <th>
                                                Batch Detail
                                            </th>
                                            <th>	Start Date</th>
                                            <th>End Date</th>
                                            <th></th>
                                            <th>Sessions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                               <td>{{item.COURSESHD_COURSE_ID}}</td>
                                               <td >{{item.COURSESHD_NAME}}</td>
                                               <td>{{item.COURSESHD_STARTDATE}}</td>
                                               <td>{{item.COURSESHD_ENDDATE}}</td>
                                              <td class=" text-center " style="display: flex;" ><input type="image"    src="./../../../assets/images/sprites/edit.svg" class="edit_icon text-center" (click)="edit(item)">

                                              
                                                <button class="btn"><i class="fa fa-user" (click)="navigate(item,'courseSchedule-AssignUser')"  style='font-size:24px' > <span class="span" >Users</span> </i>
                                                </button>
                                                 <button class="btn"><i class='fa fa-user-plus' (click)="navigate(item,'courseSchedule-AssignTrainer')"  style='font-size:24px'> <span class="span" >Add Trainers</span> </i> </button> 
                                            </td>
                                                <td class="text-center"><input type="image" (click)='sessions(item)'    src="./../../../assets/images/sprites/Sessions.png" class="  text-center"></td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="false">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>