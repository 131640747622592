<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <h5 class="text-center"> Assignments</h5>

            <div>
                <div class="text-right">
                    <button type="button" class="btn btn-primary" (click)="add()">Add</button>
                </div>
                <br>

                <div *ngIf="assignments.length">
                    <table id="patreggraph" datatable
                        class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                        <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                            <tr>
                              <th>Course Name</th>
                              <th>	Course Schedule Name</th>
                              <th>Chapter Name	</th>
                              <th>	Assignment Name</th>
                              <th>	Start Date</th>
                              <th>	End Date</th>
                              <th>
                                Max Marks
                              </th>
                              <th>
                                Status
                              </th>
                              <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of assignments ">
                                <td>{{item.ASSIGNMENT_COURSE}}</td>
                                <td>{{item.ASSIGNMENT_COURSE_SCHEDULE}}</td>
                                <td>{{item.CHAPTER_NAME}}</td>
                                <td>{{item.ASSIGNMENT_NAME}}</td>
                                <td>{{item.ASSIGNMENT_START_DATE |date:'shortDate'}}</td>
                                <td>{{item.ASSIGNMENT_END_DATE |date:'shortDate'}}</td>
                                <td>{{item.ASSIGNMENT_MAX_MARKS}}</td>
                                <td>{{item.ASSIGNMENT_STATUS}}</td>
                                <td><img src="./../../../assets/images/sprites/edit.svg" class="edit_icon" (click)="edit(item)" ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>