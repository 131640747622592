<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0">Evaluate Assessment</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Course Name : </label>
                                        <select [(ngModel)]='courceId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="courceChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Course Schedule :</label>
                                        <select [(ngModel)]='schedulId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="schedulChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                                {{schedul.COURSESHD_NAME}}</option>

                                        </select>


                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Assessment Name: </label>
                                        <select [(ngModel)]='assessmentId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of assessments" [value]='item.ASSESSMENT_ID'>
                                                {{item.ASSESSMENT_ASSESSMENT_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Assessment Date </label>
                                        <input [(ngModel)]='date' (change)="dateChange()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" type="date">
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Assessment Time: </label>
                                        <select [(ngModel)]='time'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="changeTime()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of times" [value]='item.SA_ID'>
                                                {{item.SA_START_TIME}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">User </label>
                                        <select [(ngModel)]='userId'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="userChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of users" [value]='item.UserId'>
                                                {{item.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th>
                                                Question</th>
                                            <th>
                                                Correct Answer
                                            </th>
                                            <th>Student Answer</th>
                                            <th>Result</th>
 
                                            <th>Marks</th>

                                            <th>Max Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                            <td>{{item.QUESTION_QUESTION}}</td>
                                            <td>{{item.QUESTION_ANSWER}}</td>
                                            <td>{{item.ANSWER_GIVENANSWER}}</td>
                                            <td>
                                                <div class="radio" >
                                                    <label><input type="radio" [value]='1' [(ngModel)]="item.Answer"
                                                            name={{item.ANSWER_ID}}>
                                                        correct</label>
                                                    <label><input type="radio" [value]='0' [(ngModel)]="item.Answer"
                                                        name={{item.ANSWER_ID}}>
                                                        Wrong</label>
                                                </div>
                                            </td>
                                            <td> <input type="number" [(ngModel)]="item.Marks" (change)="marksChange(item)"></td>
                                            <td>{{item.ANSWER_ACTUALMARKS}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="text-center">
                                    <button type="button" class="btn btn-primary" (click)="submit()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


