import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
   roleId:number|string=localStorage.getItem('RoleId')||3;
   menus:Array<any>=[]
  constructor( private route: ActivatedRoute, private router:Router,private service  : CommonService ) {
    this.getMenu();
   }

  ngOnInit(): void {
    // this.menus = this.route.snapshot.data.menu;
    // console.log(this.menus)
  }
  getClass(name){
    return "mdi "+name+ " menu-icon"
  }
 
  navigate(path,menu){
    if(!menu.childs.length&&path){
     this.router.navigate(['home/'+path])
    }
  
  }
   getMenu(){
     let payLoad={
      RoleId:parseInt( localStorage.getItem('RoleId')),TENANT_CODE:parseInt(localStorage.getItem('TenantCode'))}
     this.service.postCall('Account/LoadMenusByRoleId',payLoad).subscribe((res)=>{
        this.menus=res;
     },err=>{})
   }
   getId(id:any){
    return id.replaceAll(/\s/g,'')
   }
}
