


    <div class="default_class">
        <div class="content-wrapper p-0">
      
      <div class="container-fluid">
      
        <div class="row">
            <div class="col-xl-12">
                <div class="card rounded shadow-lg" >
                <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                  <h5 class="mb-0">Assessments </h5> 
                </div>   
                <div class="card-body">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                <label for="sel1">Course Name : </label>
                                <select [(ngModel)]='courceId' [disabled]="isParam"  id="sel1" (change)="courceChange()"  class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let course of cources" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                    </option>
        
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="sel1">Course Schedule :</label> 
                                <select  [(ngModel)]='schedulId ' [disabled]="isParam"  id="sel1" class="form-control form-control-sm shadow-sm rounded-lg" (change)="schedulChange()">
                                    <option value="" cl [selected]="true">select</option>
                                    <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                        {{schedul.COURSESHD_NAME}}</option>
    
                                </select>

                             
                            </div>
                        </div>
                     
                
                    </div>


        </div>
        <div *ngIf="table.length">
            <table id="patreggraph" datatable class="table table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                <thead class="top bg-info">
                    <tr>
                        <th>
                            Assessment Name</th>
                            <th>
                                Date
                            </th>
                            <th>Start Time</th>
                            <th>	End Time</th>
                            <th>No Of Questions</th>
                            <th>Duration (Mins)</th>
                            <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of table">
                        <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                        <td>{{item.SA_DATE}}</td>
                        <td>{{item.SA_START_TIME}}</td>
                        <td>{{item.SA_END_TIME}}</td>
                        <td>{{item.ASSESSMENT_NO_OF_QUESTIONS}}</td>
                        <td>{{item.ASSESSMENT_TIMINGFORASSESSMENT}}</td>
                        <td><a (click)="startExam(item)" class="a_click">Start Exam</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>