<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0"> Excel Upload</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Excel Template	 : </label>
                                        <select [(ngModel)]='courseId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="courseChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="sel1">Upload Excel	 :</label>
                                        <input type="file" (change)='changeFile($event)' style="min-height: 39.2px;" class="form-control form-control-sm shadow-sm rounded-lg">


                                    </div>
                                    
                                    <div class="col-md-3">
                                        <label for="sel1"></label><br>
                                        <button class="btn btn-success" [disabled]='!courseId||!file' (click)='submit()'>Submit</button>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>