import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare function googleTranslate():any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userName:string=localStorage.getItem('Username')
  constructor(private route: Router) { }

  ngOnInit(): void {
    googleTranslate();
  }

  Signout(){
    localStorage.clear();
    this.route.navigate(['/login'])
  }
  menuClick(){
    $('#sidebar').toggleClass('slide');
  }

}
