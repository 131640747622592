import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-cources',
  templateUrl: './cources.component.html',
  styleUrls: ['./cources.component.css']
})
export class CourcesComponent extends BaseComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  courses:Array<any>=[];
  constructor(private fb: FormBuilder,  CommonService: CommonService,  toastr: ToastrService,private route:Router) {
    super(CommonService,toastr);
    this.loadCourse();
    this.loadCourseCategory()
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      COURSE_NAME:['',Validators.required],
      COURSE_CATEGORY_ID: ['', Validators.required,],
      COURSE_DESCRIPTION: ['',],
      Duration:['',Validators.required],
      COURSE_FEES:['',Validators.required],
      COURSE_DESIGNED_FOR:['',Validators.required],
      ISPREASSESSMENT:[false,Validators.required],
      ISINTERNALEXAM:[true,Validators.required],
      ISTUDYTIMEREQUIRED:[],
      COURSE_STATUS: [true]
    })
  }

  setDefault() {
    let ctrl = this.myForm.controls;
    ctrl['COURSE_STATUS'].setValue(true);
    ctrl['ISPREASSESSMENT'].setValue(true)
    ctrl['ISINTERNALEXAM'].setValue(false)

  }
  
  loadCourse() {
    this.activeSpinner()
    let payLoad = {
      TENANT_CODE: this.tId||this.TenantCode
    }
    this.CommonService.postCall("LoadCourse", payLoad).subscribe((res: any) => {
      this.table=[];
       this.table=res;
       this.renderDataTable()
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }
  loadCourseCategory(){
    
    this.activeSpinner()
    let payLoad = {
      TENANT_CODE: localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall("LoadCourseCategory", payLoad).subscribe((res: any) => {
      this.courses = res;
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })

  }


  close() {
    this.isEdit = false;
    this.myForm.reset()
    this.setDefault();
  }
  add() { }
  edit(data) {
    let payLoad = this.editData = {
      COURSE_ID: data.COURSE_ID
    }
    this.isEdit = true;
    this.CommonService.postCall('EditCourse', payLoad).subscribe((res: any) => {
       if(res instanceof Array){
      if(res.length){
         this.editData=res[0];
        this.setData();
      }
    }else{
      this.editData=res;
        this.setData();
    }
    }, err => { })
  }
  setData() {
    let ctrls: any = this.myForm.controls;
    Object.keys(ctrls).map((key: string) => {
      let control: FormControl = ctrls[key];
      let value=this.editData[key];
      if(value!=undefined)  control.setValue(value);
     
    });
    ctrls['Duration'].setValue(this.editData['COURSE_DURATION'])
    // ctrls['ISPREASSESSMENT']
    ctrls['ISPREASSESSMENT'].setValue(this.editData['IS_PREASSESSMENT']?true:false)
    ctrls['ISINTERNALEXAM'].setValue(this.editData['IS_INTERNALEXAM'])


  }
  onSubmit(form: FormGroup) {
    let payLoad = form.getRawValue();
    if(form.invalid) return
    payLoad["CREATEDBY"] = this.editData.CREATEDBY || localStorage.getItem('UserId');
    payLoad.TENANT_CODE = localStorage.getItem('TenantCode');
    payLoad.CREATEDDATE = new Date();
   if( !payLoad.ISTUDYTIMEREQUIRED||payLoad.ISTUDYTIMEREQUIRED==''){
     payLoad.ISTUDYTIMEREQUIRED=false;
   }
    if (this.isEdit) {
      payLoad.COURSE_ID = this.editData.COURSE_ID;
      this.CommonService.postCall('UpdateCourse', payLoad).subscribe((res: any) => {
        this.loadCourse();
        this.toastr.success('Course  Updated Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error?err.error:'Course Type  not Updated')
      })
    } else {
     
      this.CommonService.postCall('CreateCourse', payLoad).subscribe((res: any) => {
        this.loadCourse();
        this.toastr.success('Course  created Successfully')
        document.getElementById('md_close').click()
      }, err => {
        this.toastr.error(err.error?err.error:'Course Type  not created ')
      })
    }
  }
  navigate(data,route){
     this.route.navigate(['home/'+route],{queryParams:{id:data.COURSE_ID}})
  }
  changeTname() {
    this.loadCourse()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
