import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.css']
})
export class WorkExperienceComponent extends BaseComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  empStatus: Array<any> = [];
  WorkExperienceList: Array<any> = []
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      EmployerName: ['', Validators.required],
      EmployementStatus: ['', Validators.required],
      DurationFrom: ['', Validators.required],
      DurationTo: ['', Validators.required],
      Designation: ['', Validators.required],
      JobProfile: ['', Validators.required],
      NoticePeriod: ['', Validators.required],

    });
    this.getAll();
    this.parent.childs['WorkExperienceList'] = this;
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      // this.WorkExperienceList = this.editData['WorkExperience'].slice(0);
      this.dataTransform(this.editData['WorkExperience'].slice(0));
    }
  }
  dataTransform(data: Array<any>) {
    data.map(item => {
      let obj = {
        type: item['<type>k__BackingField'],
        UserWorkExpID: item['<UserWorkExpID>k__BackingField'],
        EmployerName: item['<EmployerName>k__BackingField'],
        EmployementStatusName: item['<EmployementStatus>k__BackingField'],
        EmployementStatus: '',
        DurationFrom: item['<DurationFrom>k__BackingField'] ? moment(item['<DurationFrom>k__BackingField']).format('yyyy-MM-DD') : '',
        DurationTo: item['<DurationTo>k__BackingField'] ? moment(item['<DurationTo>k__BackingField']).format('yyyy-MM-DD') : '',
        Designation: item['<Designation>k__BackingField'],
        JobProfile: item['<JobProfile>k__BackingField'],
        NoticePeriod: item['<NoticePeriod>k__BackingField']
      }
      this.table.push(obj);
    })
    this.addIds()
  }
  getAll() {
    //GetEmployementStatus
    this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.EmployementStatus}).subscribe(
      (res: any) => {
        this.empStatus = res;
        this.addIds();
      }
    );

  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(this, 'WorkExperienceList', i);;
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      value['UserWorkExpID'] = this.editData['UserWorkExpID']
      let data = Object.assign({}, value);
      this.createAndUpdate(data, false)
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.createAndUpdate(data, true)
    }
    document.getElementById('md_close_work').click();
  }
  addIds() {
    this.table.map(item => {
      let index = this.empStatus.findIndex(x => x.DICTIONARYNAME == item.EmployementStatusName);
      if (index > -1) item.EmployementStatus = this.empStatus[index]['DICTIONARYID']
    })
    this.WorkExperienceList = this.table.slice(0)
  }
  createAndUpdate(data, type) {
    let i = this.index;
    let index = this.empStatus.findIndex(x => x.DICTIONARYID == data.EmployementStatus);
    if (index > -1) {
      data.EmployementStatusName = this.empStatus[index]['DICTIONARYNAME']
    }

    if (type) {
      this.table.push(data);
      let obj = Object.assign({}, data);
      this.WorkExperienceList.push(obj);
      this.parent.toastr.success('Added successfully')
    } else {
      this.table[i] = data;
      let obj = Object.assign({}, data);
      this.WorkExperienceList[i] = obj
      this.parent.toastr.success('Updated successfully')
    }
  }
}
