<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center"> Blogs</h5>
                            <!-- data-toggle="modal" data-target="#myModal" -->
                        </div>
                        <div class="card-body">
                          <div class="mb-2" style="display: flex;justify-content: space-between;">                          
                            <button  data-toggle="modal" data-target="#myModal" class="btn btn-success">Compose New Post</button>
                            <div class="float-right" style="display: flex;">
                                <input  [(ngModel)]='searchText' type="text" placeholder="search by post title  or label" class="form-control">&nbsp;<i class="fa fa-search icon_search text-primary pointer" (click)='search()' aria-hidden="true"></i>
                            </div>
                        </div>
                            <div>
                                <div class="card flex-row flex-wrap mb-1" *ngFor='let item of blogs'>
                                    <div class="card-heade border-0">
                                        <img src="//placehold.it/200" alt="">
                                    </div>
                                    <div class="card-block px-2">
                                        <h4 class="card-title mt-1">{{item.BlogTitle}}</h4>
                                        <span class="text-dark" style="font-size: 12px;">{{item.NAME}} &nbsp; {{item.CreatedOn}}</span>
                                        <p class="card-text">{{item.BlogMessage}}</p>
                                        <a (click)="view(item)" class="btn text-danger">Continue Reading...</a>
                                    </div>
                                    <div class="w-100"></div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Compose Post</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Title <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="BlogTitle">

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                lable <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" placeholder="" class="form-control" formControlName='Labels'>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Reader Comments <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <div class="radio">
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input value=1 type="radio" formControlName='IsCommentsShow'
                                                    class="form-check-input" name="IsCommentsShow">Allow
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input value=0 type="radio" formControlName='IsCommentsShow'
                                                    class="form-check-input" name="IsCommentsShow">Don't Allow
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">Message <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea formControlName='BlogMessage'
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Upload Image </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="file" (change)="changeFile($event)" placeholder=""
                                        class="form-control">
                                </div>
                                <p>{{fileName}}</p>
                            </div>
                        </div>

                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmit(myForm,'save')"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" class="btn btn-success" (click)="onSubmit(myForm,'publish')"
                    [disabled]="myForm.invalid">Publish</button>
                <button type="button" class="btn btn-danger" id="md_close" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
