<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"
            data-target="#myModal_addr">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>Address Type</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Address Details</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.AddressTypeName}}</td>
                <td>{{item.CountryName}}</td>
                <td>{{item.StateName}}</td>
                <td>{{item.CityName}}</td>
                <td>{{item.Pincode}}</td>
                <td>{{item.AddressDetails}}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" data-toggle="modal"   data-target="#myModal_addr" (click)="edit(item,i)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_addr" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Address </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Address</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Address Type <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='AddressType'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select Address Type</option>
                                        <option *ngFor='let item of addrTypes' [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>

                                </div>
                                <div *ngIf="myForm.get('AddressType').touched">
                                    <span class="text-danger" *ngIf="myForm.get('AddressType').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Country <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='Country' (change)='changeCountry($event.target.value)'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select Country</option>
                                        <option *ngFor='let item of country' [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>

                                </div>
                                <div *ngIf="myForm.get('Country').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Country').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> State <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='State' (change)='changeState($event.target.value)'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select State</option>
                                        <option *ngFor='let item of states' [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>

                                </div>
                                <div *ngIf="myForm.get('State').touched">
                                    <span class="text-danger" *ngIf="myForm.get('State').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> City <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='City'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select City</option>
                                        <option *ngFor='let item of city' [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>

                                </div>
                                <div *ngIf="myForm.get('City').touched">
                                    <span class="text-danger" *ngIf="myForm.get('City').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Pincode <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min="1" formControlName='Pincode'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('Pincode').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Pincode').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Address Details <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="Enter Address Details" formControlName='AddressDetails'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                                </div>
                                <div *ngIf="myForm.get('AddressDetails').touched">
                                    <span class="text-danger" *ngIf="myForm.get('AddressDetails').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                             
                      
                        
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_lan" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>