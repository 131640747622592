<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 *ngIf="!isEdit" class="mb-0 text-center">Add Course Schedule</h5>
                            <h5 *ngIf="isEdit" class="mb-0 text-center">Edit Course Schedule</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                        <fieldset>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Course <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select [(ngModel)]='courceId '  formControlName='COURSESHD_COURSE_ID'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    (change)="setscheduleName('cource')">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let course of cources" [value]='course.COURSE_ID'>
                                                        {{course.COURSE_NAME}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Course Type <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_COURSETYPE_ID'
                                                    class="form-control form-control-sm shadow-sm rounded-lg ">
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of courceTypes" [value]='item.COURSE_TYPE_ID' >{{item.COURSE_TYPE_NAME}}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Academic Year <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_ACADAMICYEAR' (change)= "setscheduleName()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of acadamicYears" [value]='item.DICTIONARYID' >{{item.DICTIONARYNAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Course Year <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_COURSEYEAR' (change)= "setscheduleName()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of courceYears" [value]='item.DICTIONARYID' >{{item.DICTIONARYNAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Semester <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_SEMESTER' (change)= "setscheduleName()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of semesters" [value]='item.DICTIONARYID' >{{item.DICTIONARYNAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Subject <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select formControlName='COURSESHD_SUBJECT' (change)= "setscheduleName()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of subjects" [value]='item.SUBJECT_ID' >{{item.SUBJECT_NAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Section</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_SECTION' (change)= "setscheduleName()"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="0" [selected]="true">select</option>
                                                    <option *ngFor="let item of sections" [value]= "item.DICTIONARYID" >{{item.DICTIONARYNAME}}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Regulation</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_REGULATION_ID'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="0" [selected]="true">select</option>
                                                    <option *ngFor="let item of regulations" [value]='item.REGULATIONS_ID' >{{item.REGULATIONS_NAME}}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Country <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_COUNTRY'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of countrys" [value]='item.DICTIONARYID' >{{item.DICTIONARYNAME}}</option>
                                                   
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Batch Details</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input formControlName='COURSESHD_NAME'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                    type="text" disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Location Name <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select  formControlName='COURSESHD_LOCATION_ID' (change)="getRoomsByLocation($event.target.value)"
                                                    class="form-control form-control-sm shadow-sm rounded-lg " >
                                                    <option value="" [selected]="true">select</option>
                                                    <option *ngFor="let item of locations" [value]='item.LOCATION_ID' >{{item.LOCATION_NAME}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Class Room </label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select formControlName='COURSESHD_ROOM_ID' (change)="getNumberofSeatsByRoom($event.target.value)"
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option value="0" [selected]="true">select</option>
                                                    <option *ngFor="let item of rooms" [value]='item.ROOM_ID' >{{item.ROOM_NO}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            No Of Sessions</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input type="number" formControlName='COURSESHD_NOOFDAYS'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            No Of Seats</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input formControlName='COURSESHD_NO_OF_STUDENTS' [(ngModel)]='seats'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   type='number' disabled>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Start Date <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input min="{{minDate}}"  (change)="sTimeCahnge()" formControlName='COURSESHD_STARTDATE'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   type="date">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Start Time <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input type="time"  formControlName='COURSESHD_STARTTIME'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            End Date</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input  formControlName='COURSESHD_ENDDATE'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   type="date">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            End Time <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <input  (change)="timeChange($event.target.value)"   type="time" formControlName='COURSESHD_ENDTIME'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Status</label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group"> <strong>:</strong> &nbsp;
                                                <select formControlName='COURSESHD_STATUS'
                                                    class="form-control form-control-sm shadow-sm rounded-lg "
                                                   >
                                                    <option [ngValue]=1 [selected]="true">Active</option>
                                                    <option [ngValue]=0>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            
                            </div>
                        </fieldset>
                    </form>
                    <div class="text-center">
                        <button type="button" [disabled]="myForm.invalid"*ngIf="!isEdit" (click)="onSubmit(myForm)" class="btn btn-success">Save</button> 
                        <button type="button" [disabled]="myForm.invalid" *ngIf="isEdit" (click)="onSubmit(myForm)" class="btn btn-success" >Update</button> 
                        &nbsp;&nbsp;
                        <button
                            type="button" class="btn btn-danger" (click)="close()" id="md_close" >cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>