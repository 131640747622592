import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-cource-schedule',
  templateUrl: './cource-schedule.component.html',
  styleUrls: ['./cource-schedule.component.css']
})
export class CourceScheduleComponent extends BaseComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  constructor( CommonService: CommonService,  toastr: ToastrService, private route: Router) {
    super(CommonService,toastr); 
    this.load();
  }

  ngOnInit(): void {

  }
  load() {
    this.activeSpinner();
    let payLoad = {
      "RoleId": this.roleId,
      "COURSETRAINERID": this.userId,
      "TENANT_CODE":this.TenantCode,
      "USERID":this.userId
    }

    this.CommonService.postCall('LoadCourseScheduleBatchPlan', payLoad).subscribe((res: any) => {
      this.table = [];
     this.table=res;
     this.renderDataTable()
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
 
  }
  //CourseshAssigntrainersComponent courceSchedule-AssignTrainer  courceSchedule-AssignUser
  add() {
    this.route.navigate(['home/courseSchedule/add'])
  }
  close() {

  }
  edit(item) {
    this.route.navigate(['home/courseSchedule/edit'], { queryParams: { cId: item.COURSESHD_ID } })
  }
  navigate(data, route) {
    this.route.navigate(['home/' + route], { queryParams: { csId: data.COURSESHD_ID, cId: data.COURSE_ID } })
  }
  sessions(item) {
    let params = {
      csId: item.COURSESHD_ID,
      cId: item.COURSE_ID,
      sDate:item.COURSESHD_STARTDATE,
      eDate:item.COURSESHD_ENDDATE
    }
    this.route.navigate(['home/courseSessions'], { queryParams: params })

  }
  changeTname() {
    this.load()
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}

