<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Tenant</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  
                                   (click)="add()" >Add New Tenant</button>      
                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable class=" table-striped table-bordered mt-2 pt-2 table-sm small" >
                                    <thead class="top bg-info">
                                        <tr>
                                            <th>	
                                                Tenant Code</th>
                                                <th>
                                                    Tenant Name
                                                </th>
                                               
                                                <th>Belongs To</th>
                                                <th>Status</th>
                                                <th>Description</th>
                                                <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                            <td>{{item.TNT_CODE}}</td>
                                            <td>{{item.TNT_NAME}}</td>
                                            <td>{{item.TENANT_PARENT}}</td>
                                            <td>{{item.TNT_STATUS}}</td>
                                             <td>{{item.TNT_DESCRIPTION}}</td>
                                             <td style="display: flex;">   <input type="image"    src="./../../../assets/images/sprites/edit.svg" class="edit_icon" (click)="edit(item)">  
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

