import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  educationTypes: Array<any> = [];
  educationModes: Array<any> = [];
  educationNames: Array<any> = [];
  Specialization: Array<any> = [];
  Institutes: Array<any> = [];
  index: number;
  educationData: Array<any>=[];
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      EducationType: ['', Validators.required],
      EducationMode: ['', Validators.required],
      EducationName: ['', Validators.required],
      Specialization: ['', Validators.required],
      UniversityInstitute: ['', Validators.required],
      YearOfPassing: ['', Validators.required]
    });
    this.getEducationGroup();
    this.parent.childs['EducationTypeList']=this
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.educationData = this.editData['Education'].slice(0);
      this.dataTransform(this.editData['Education'].slice(0));
    }
  }
  dataTransform(data) {
    this.table = data
  }
  getEducationGroup() {
    this.parent.CommonService.postCall('GetEducationGroup', {}).subscribe(
      (res: any) => {
        if (Object.keys(res).length) {
          [this.educationTypes, this.educationModes, this.Specialization, this.Institutes] = [res.EducatonType, res.EducationMode, res.Specialization, res.UniversityInstitute]
        }
      }
    )
  }
  add() {
   this.isEdit=false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit=true;
  }
  close() {
    this.myForm.reset()
   }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      let data = Object.assign({}, value);
      this.table[this.index] = data;
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.table.push(data);
    }
    document.getElementById('md_close_education').click();
  }
  modeChange(value) {
    this.parent.activeSpinner();
    this.parent.CommonService.postCall('GetEducationName', { EducationModeId: value }).subscribe(
      (res: any) => {
        this.educationNames = res;
        this.parent.deactivateSpinner()
      }, err => {
        this.parent.deactivateSpinner()
      }
    )
  }
  //8499823644
}
